'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitGroupsManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitGroupsManager', [
    'MundoDefaultManager'
    'TenantManager'
    'UnitsManager'
    '$mdDialog'
    '$log'
    'UserRoleManager'
    'UserContextManager'
    'UnitMarkersManager'
    'RestUtils'
    '$q'
    (
      MundoDefaultManager
      TenantManager
      UnitsManager
      $mdDialog
      $log
      UserRoleManager
      UserContextManager
      UnitMarkersManager
      RestUtils
      $q
    ) ->
      UnitGroupsManagerBase = new MundoDefaultManager()
      UnitGroupsManagerBase.setUrl('units/groups')
      UnitGroupsManagerBase.setAutoTenant()
      UnitGroupsManagerBase.setNewObject(['label', 'parameters', 'units', 'marker'])
      UnitGroupsManagerBase.setUpdateObject(['label', 'parameters', 'units', 'marker'])
      UnitGroupsManagerBase.setObject('unitGroupUser', ['userContext', 'roles'])
      UnitGroupsManagerBase.setSearchFields(['label', 'units.label', 'tenant.label'])

      UnitGroupsManagerBase.new = (data) ->
        if (data.units)
          data.units = @extractIds(data.units)

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      UnitGroupsManagerBase.update = (id, data) ->
        if (data.units)
          data.units = @extractIds(data.units)

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

    #      @transport.one(@conf.url, id).all('users').getList({limit: 20})

      UnitGroupsManagerBase.addUser = (id, data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        if data.userContext and typeof data.userContext == 'object'
         data.userContext = data.userContext.id

        @transport.one(@conf.url, id).all('users').post(_.pick(data, @getObject('unitGroupUser')))

      UnitGroupsManagerBase.removeUser = (unitId, contactId) ->
        @transport.one(@conf.url, unitId).one('users', contactId).remove()

      UnitGroupsManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'units'
          name: 'units'
          type: 'modelchips'
          templateOptions:
            label: 'Units'
            placeholder: 'units'
            required: true
            multiple: true,
            autocompleteLabel : 'add  or remove a unit'
            labelProp: "label"
            valueProp: "id"
            options: UnitsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'marker'
          name: 'marker'
          type: 'select'
          defaultValue : null
          templateOptions:
            label: 'Marker'
            placeholder: 'marker'
            required: false
            labelProp: "code"
            valueProp: "code"
            options: UnitMarkersManager.getFullList().$object
        ]

      UnitGroupsManagerBase.getEditForm = (data) ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'units'
          name: 'units'
          type: 'modelchips'
          defaultValue: _.filter data.units ,(unit) -> !unit.deletedAt?
          templateOptions:
            label: 'Units'
            placeholder: 'units'
            required: true
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a unit'
            valueProp: "id"
            options: UnitsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'marker'
          name: 'marker'
          type: 'select'
          defaultValue : data.marker
          templateOptions:
            label: 'Marker'
            placeholder: 'marker'
            required: false
            labelProp: "code"
            valueProp: "code"
            options: UnitMarkersManager.getFullList().$object
        ]
      UnitGroupsManagerBase.getUserForm = ->
        [
          key: 'userContext'
          name: 'userContext'
          type: 'select'
          templateOptions:
            label: 'User context'
            placeholder: 'User context'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UserContextManager.getFullList().$object
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            multiple: true,
            placeholder: 'roles'
            autocompleteLabel : 'add  or remove a role'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ]
      UnitGroupsManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
  #       ,
  #         key: 'tenant.label'
  #         title: 'datatable.label.tenant'
  # #        sort: 'tenant.label'
  #         hideInList: true
        ]

      assignUsers =
        [
          icon: 'people'
          text: 'app.admin.actions.units.groups.assignUsers'
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/units/views/unit-group-users.modal.tpl.html'
              controller: 'UnitGroupUserCtrl'
              controllerAs: 'ctrl'
              skipHide: true
              locals:
                id: id
        ]

      UnitGroupsManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/units/views/unit-groups-detail.tpl.html'

      UnitGroupsManagerBase.getDetailModalTemplateUrl = ->
        'mundo-admin/units/views/unit-groups-detail-modal.tpl.html'

      UnitGroupsManagerBase.getOne = UnitGroupsManagerBase.one

      UnitGroupsManagerBase.one = (id) ->
        UnitGroupsManagerBase.getOne(id).then (group) ->
          group.groupUsers = []

          for v, k in group.unitGroupUsers
            if v.deletedAt
              continue

            UnitGroupsManagerBase.transport.all(UnitGroupsManagerBase.getUrl())
              .one(id)
              .one('users')
              .one(v.id)
              .get()
              .then (u) ->
                group.groupUsers.push u

          return group

      UnitGroupsManagerBase.addExtraAction(assignUsers)

      UnitGroupsManagerBase.editPermission = 'manage all MundoMosaUnitBundle:UnitGroup entities'

      UnitGroupsManagerBase
  ]
